@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* > * {
  font-family: "Poppins", sans-serif;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #d36128;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
  /* #d36128 */
}

.ant-layout {
  min-height: 100vh;
}

.ant-layout-slider {
  flex: 0 0 250px !important;
  max-width: 250px !important;
  min-width: 250px !important;
  width: 250px !important;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.btn {
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  padding: 20px;
  border-radius: 10px;
}

.sm-logo {
  display: none;
}

.ant-layout-sider-collapsed .sm-logo {
  display: block;
}

/* .ant-layout-sider-children{
  background-color: #36338c;
}  */

.ant-layout-sider-collapsed .lg-logo {
  display: none;
}

.logo {
  background-color: #d36128;
  margin: 0;
  height: 64px;
}

main {
  overflow-y: hidden;
  background-color: transparent !important;
  margin: 0 !important;
}

.ant-layout-header div .badge {
  top: -10px;
  right: -4px;
}
.dboard h4 {
  font-weight: 500;
  margin-bottom: 0px;
  font-size: 18px;
  color: 000002;
}

.green {
  color: green;
}

.red {
  color: red;
}
/* .ant-layout-header h5{
  line-height: 30px !important;
}
.ant-layout-header p{
  line-height: 20px !important;
} */

/* LoGIN PaGE */
.glass {
  background: rgba(255, 255, 255, 0.55);
  border-radius: 16px;
  box-shadow: 0 4px 30px #4747470b;
  backdrop-filter: blur(7.1px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  @apply border-4 border-gray-50 shrink-0 h-3/4 rounded-3xl py-10 px-7 min-w-max;
}

.logpage {
  background: #dcdce6;
  background-image: url("./assets/Background.png");
}

/* Loader */
.loader {
  width: 40px;
  height: 40px;
  border: 5px solid #cadcf2;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
  justify-content: center;
}
.loader:after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  border: 5px solid #cadcf2;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
  justify-content: center;
}

/* .ant-picker-o */

:where(.css-dev-only-do-not-override-1n7nwfa).ant-btn-primary {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

:where(.css-1n7nwfa).ant-btn-primary {
  color: #fff;
  background-color: #1677ff;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
}

/*  */

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0);
  }
  60%,
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse {
  0%,
  60%,
  100% {
    transform: scale(1);
  }
  80% {
    transform: scale(1.2);
  }
}
